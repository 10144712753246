.locationCard {
  background: #fff;
  height: 500px;
  width: 100%;
  .locationItems {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-evenly;
    padding: 50px 30px;
    &__item {
      display: flex;
      gap: 10px;
      align-items: center;
      h3 {
        font-size: 22px;
      }
      a {
        font-size: 19px;
      }
    }
  }
}

@media (max-width: 800px) {
  .locationCard {
    .locationItems {
      &__item {
        h3 {
          font-size: 20px !important;
        }
        a {
          font-size: 17px !important;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .locationCard {
    .locationItems {
      &__item {
        h3 {
          font-size: 13px !important;
        }
        a {
          font-size: 15px !important;
        }
      }
    }
  }
}
