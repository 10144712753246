.card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 300px;

  .card__text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    row-gap: 20px;
    max-width: 50%;
  }
  .card__animation {
    max-width: 35%;
  }
}

.card_right {
  flex-direction: row-reverse;
}

@media (max-width: 880px) {
  .card {
    justify-content: space-between !important;
    .card__animation {
      max-width: 45%;
    }
  }
}

@media (max-width: 585px) {
  .card {
    justify-content: space-between !important;
    flex-direction: column;
    .card__text {
      max-width: 100%;
    }
    .card__animation {
      max-width: 75%;
    }
  }
}
