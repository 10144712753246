.header {
  transition: all 0.3s ease-in;
  width: 100%;
  height: 150px;
  position: fixed;
  z-index: 100;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .links {
      &__anchor {
        display: flex;
        gap: 40px;
      }
    }
    .burger {
      display: none;
    }
    .phone {
      a {
        display: flex;
        gap: 10px;
        align-items: center;
        color: var(--dark);
        svg {
          path:last-child {
            fill: var(--second-color) !important;
          }
        }
        h2 {
          color: var(--second-color);
        }
      }
    }
  }
}

.headerIsScrolled {
  height: 100px;
  transition: all 0.3s ease-in;
  background: white;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
}
.notMain {
  height: 100px !important;
  background: #fff !important;
  .burger {
    display: none !important;
  }
}
@media (max-width: 800px) {
  .header {
    .container {
      .links {
        display: none;
      }
      .burger {
        display: block;
      }
      .phone {
        a {
          h2 {
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .header {
    height: 80px;
    .container {
      .phone {
        a {
          h2 {
            font-size: 15px;
          }
        }
      }
    }
  }
}
