.text {
  margin: 25px 0;
  padding: 10px 0;
  &__title {
    display: flex;
    height: 100%;
    align-items: center;
    gap: 10px;
    &__colorBox {
      height: 40px;
      width: 20px;
      border-radius: 3px;
    }
    &__text {
      color: var(--main-color);
      position: relative;
      font-size: 22px;
    }
  }
  &__description {
    color: var(--gray);
    margin-top: 10px;
  }
}


@media (max-width: 800px) {
  .text {
    &__title {
      &__colorBox{
        height: 40px;
      }
      &__text {
        font-size: 18px;
      }
    }
    &__description{
      font-size: 13px;
    }
  }
}
@media (max-width: 500px) {
  .text {
    &__title {
      &__text {
        font-size: 13px;
      }
    }
    &__description{
      font-size: 9px;
    }
  }
}
