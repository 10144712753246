.logo{
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__img{
        width: auto;
        width: 200px;
        height: 100%;
        @media(max-width:450px){
            width: 100px;
        }
    }
}