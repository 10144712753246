.services {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 50px 0;
  gap: 40px;

  .services__simple {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 300px;
    column-gap: 15px;

    p {
      line-height: 22px;
      font-size: 14px;
    }

    & > div {
      width: 25%;
    }
  }
  .services__ani {
    display: flex;
    flex-direction: column;
    row-gap: 25px;

    p {
      line-height: 26px;
      font-size: 16px;
    }
  }
}

@media (max-width: 880px) {
  .services__simple {
    flex-direction: column;
    row-gap: 30px;
    & > div {
      width: 100% !important;
    }
  }

  .services__ani {
    p {
      line-height: 22px !important;
      font-size: 14px !important;
    }
  }
}


@media (max-width: 635px) {
  .services__simple {
    flex-direction: column;
    row-gap: 30px;
    & > div {
      width: 100% !important;
    }
  }

  .services__ani {
    p {
      line-height: 22px !important;
      font-size: 14px !important;
    }
  }
}
