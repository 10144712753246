.app {
  overflow-x: hidden;
}

.input {
  background-color: red !important;
}

#quotes {
  background: var(--main-color);
}
.step-form-page {
  min-height: 100vh;
  /* display: grid;
  place-items: center; */
  background-size: cover;
  background-position: center;
  padding: 120px 0 40px;
}

.ant-steps-item-icon {
  min-width: 30px !important;
}
.ant-pro-steps-form-steps-container {
  min-width: 30px !important;
  justify-content: center;
}
.ant-pro-steps-form-container {
  min-width: 30px !important;
}

.ant-space-horizontal {
  display: flex;
  width: 100%;
  justify-content: center;
}

.select-s {
  background-color: red;
  border-radius: 32px;
}
