.privancy_policy {
  padding-top: 100px;
  width: 100%;
  min-height: 800px;
  padding-top: 100px;
  background: url("../../images/showcase.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9;
  }
}
.container{
  background: var(--white);
  padding: 10px 50px;
  position: inherit;
  z-index: 10;
}
.group {
  padding: 30px 0;
  .groupName {
    padding: 10px 0;
  }
  &__group {
    .text {
      padding: 10px 0;
      &__strong {
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    .links {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .link {
        padding: 3px 0;
        color: var(--main-color);
      }
    }
  }
}
