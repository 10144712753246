.wrap {
  display: flex;
  gap: 20px;
  @media (max-width: 500px) {
    flex-direction: column;
  }
}

.form_wrap {
  background: red;
  .input {
    padding: 20px !important;
  }
}

.textarea {
  textarea {
    // background-color: red;
  }
}

.mask_input {
  padding: 5px 15px;
  border: 1px solid rgba(0, 0, 0, 0.152);
  border-radius: 6px;
  outline: 1px saddlebrown;

  transition: 0.5s;

  &:hover {
    border: 1px solid #17a2ff;
  }

  &:focus {
    border: 1px solid #17a2ff;
    box-shadow: 0px 0px 3px #59bdff;
  }

  // @media (max-width: 500px) {
  //   min-width: 100%;
  // }
}

@media (max-width: 500px) {
  .input {
    min-width: 250px;
  }
  .mask_input {
    min-width: 250px;
  }
}

@media (max-width: 380px) {
  .input {
    min-width: 200px;
    max-width: 200px !important;
  }
  .mask_input {
    min-width: 200px;
    width: 100% !important;
  }
}
