@import "/src/styles/variables.scss";

.local {
  min-height: 350px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.216);

  .step {
    display: flex;
    justify-content: space-between;
    width: fit-content;
    column-gap: 30px;
    min-width: 700px;
    margin: 0px;

    textarea {
      resize: none !important;
      min-height: 165px;
    }
  }

  .step__center {
    justify-content: center;
  }

  .step__vehicle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
    & > div {
      min-width: 150px !important;
    }
  }
  .step__vertical {
    display: flex !important;
    flex-direction: column !important;
  }

  .step__1 {
    & > div {
      min-width: 350px;
    }
  }

  .step__4 {
    & > div {
      div {
        min-width: 40%;
      }
    }
  }
  .wrap {
    width: 100%;
    min-width: 200px;
    display: flex;
    justify-content: space-between;

    .textarea {
      background-color: red($color: #000000);
    }
  }

  .resultCard {
    padding: 20px;
    min-width: 300px;

    ul {
      border-radius: 2px;
      overflow: hidden;
      li {
        display: flex;
        justify-content: space-between;
        border: 1px solid rgba(95, 158, 160, 0.485);
        padding: 4px 10px;
        span {
          margin-right: 8px;
        }
      }
    }
  }

  // .formClass {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  // }

  .wrapper {
    background-color: white;
    padding: 20px;
    border-radius: 1px;

    header {
      margin-bottom: 20px;
    }

    .form {
      & > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }

    .div1 {
      min-width: 48%;
      h1 {
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: $lg) {
  .local {
    min-height: 500px;
    height: fit-content;

    .step {
      flex-direction: column;
      width: fit-content;
      min-width: 150px !important;
      margin: 0px;
    }
    .step__1 {
      & > div {
        min-width: 150px;
      }
    }
  }
}

@media (max-width: $sm) {
  .local {
    h1 {
      font-size: 22px;
    }

    .resultCard {
      min-width: 200px;
      padding: 0px 0 20px;
    }

    .step__4 {
      & > div {
        div {
          min-width: none;
          width: 100%;
        }
      }
    }

    .wrap {
      justify-content: center;
    }
  }
}
