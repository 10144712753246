.textsWrapper {
  padding: 50px 0;
  &__heading {
    text-align: center;
    font-size: 29px;
    color: var(--main-color);
  }
  .texts {
    padding: 30px 0;
  }
}
@media(max-width:800px){
  .textsWrapper{
    &__heading{
      font-size: 24px;
    }
  }
}
@media(max-width:500px){
  .textsWrapper{
    &__heading{
      font-size: 19px;
    }
  }
}