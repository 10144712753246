@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import "./App.scss";

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  scroll-behavior: smooth;
  font-family: var(--font-family);
}
body {
  overflow-x: hidden;
}

* p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  color: var(--secondary);
}

/* #69BAF1 */

:root {
  --main-color: #0099ff;
  --second-color: #03090e;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #03090e;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family: "Poppins";
  --step-1: rgba(128, 128, 128, 0.89);
}
.container {
  width: 80%;
  margin: 0 auto;
  height: 100%;
  max-width: 1440px;
}

.ant-anchor-ink {
  background-color: var(--second-color) !important;
  height: 3px;
}
.ant-anchor-link.ant-anchor-link-active a {
  color: var(--black) !important ;
}

.ant-anchor-link a {
  font-family: var(--font-family) !important;
  padding: 7px 0;
  font-weight: 600;
  color: var(--gray) !important;
}
.ant-steps-item-title::after {
  background-color: var(--main-color) !important;
}
@media (max-width: 500px) {
  .container {
    width: 90%;
  }
}
.slick-arrow:before {
  display: none;
}
