.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.menu-item {
  a {
    color: white !important;
  }
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 50px;
  height: 100px;
  
  right: 36px;
  top: 0;
  display: none;
}

/* Color/shape of burger icon bars */
.bm-menu-wrap {
  top: 0;
  display: none ;
}
.bm-burger-bars {
  background: var(--main-color);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
// .bm-cross {
//   /* background: var(--blue); */
// }

/* General sidebar styles */
.bm-menu {
  background: var(--main-color);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: var(--main-color);
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  display: none;
}
@media (max-width: 800px) {
  .bm-menu-wrap {
    display: block ;
  }
  .bm-burger-button{
    // display: block;
  }
  .bm-overlay{
    display: block;
  }
  
}
