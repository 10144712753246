.card {
  width: fit-content;
  text-align: center;
  min-height: 360px;
  row-gap: 12px;

  img {
    margin-bottom: 18px;
  }

  & > div {
    h4 {
      margin-bottom: 12px;
    }
  }
}
@media (max-width: 880px) {
  .card {
    min-height: 200px;
  }
}
