.footer {
  height: 300px;
  background: var(--main-color);
  color: #fff;
  .container {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    flex-direction: column;
    .logo {
      height: 80%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .image {
        // width: 100px;
        // height: 100px;
        border-radius: 50%;
        // img {
        //   width: 100%;
        //   height: 100%;
        // }
      }
    }
    h3 {
      font-size: 14px;
      color: #fff;
      font-weight: 200;
    }
    a {
      color: #fff;
      text-decoration: underline;
    }
    .links {
      width: 100%;
      height: 20%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
@media (max-width: 500px) {
  .footer {
    .container {
     .links{
        height: 50%;
        flex-direction: column;
        align-items: start;
     }
    }
  }
}
@media (max-width: 350px) {
  .footer {
    .container {
     
    }
  }
}
