@import "/src/styles/variables.scss";

.showcase {
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .background{
    width: 100%;
    height: 100%;
    position: absolute;
    filter: blur(4px);
  }
  .showcase__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 20px;
    margin-bottom: 20px;
    width: 59%;
    z-index: 10;
    position: inherit;
    & > div {
      max-width: 75%;
    }
    
    h1 {
      color: white;
      font-size: 60px;
      line-height: 74px;
    }
    
    p {
      color: white;
      font-size: 16px;
      // max-width: 70%;
      text-align: left;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }

  .showcase__buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 20px;
  }

  .showcase__form {
    display: flex;
    justify-content: flex-start;
    width: 39%;
  }
}

@media (max-width: 1250px) {
  .showcase {
    .showcase__text {
      max-width: 65%;

      h1 {
        font-size: 52px;
        line-height: 62px;
      }
      p {
        font-size: 16px;
      }
      h1,
      p {
        margin-bottom: 10px;
      }

      & > div {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 830px) {
  .showcase {
    padding-top: 150px;
    & > div {
      flex-direction: column;
    }

    .showcase__text {
      max-width: none;
      width: 100%;
      margin-bottom: 30px;

      & > div {
        h1 {
          font-size: 46px;
        }
        p {
          max-width: none;
        }
      }
    }
    .showcase__form {
      width: 100%;
      max-width: 400px;
    }
  }
}

@media (max-width: $md) {
  .showcase {
    .showcase__text {
      max-width: 100%;
      & > div {
        p {
          line-height: 22px;
        }
      }
    }
  }
}

@media (max-width: $sm) {
  .showcase {
    min-height: 100vh;
    .showcase__text {
      max-width: 100%;
      & > div {
        p {
          max-width: none;
        }
        h1 {
          font-size: 32px !important;
          line-height: 42px;
        }
      }
    }
  }
}

@media (max-width: $xs) {
  .showcase {
    .showcase__text {
      row-gap: 12px;
      & > div {
        p {
          font-size: 14px !important;
        }
        h1 {
          font-size: 32px !important;
          line-height: 38px !important;
        }
      }
    }
  }
}
