.card {
  width: 100%;
  min-width: 250px;

  background: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 8px;
  height: 115px;
  margin-bottom: 16px;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px 15px;
  position: relative;
  color: white;
  margin: 0 auto;
  &__text {
    &__description {
      font-size: 15px;
    }
  }
  &__icon {
    svg {
      width: 50px;
      height: 50px;
    }
  }
  &__backgroundArrow {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 100%;
    background: url("../../../images/resultCardArrow.svg");
  }
}

@media (max-width: 768px) {
  .card {
    height: 100px;
    &__text {
      &__title {
        font-size: 20px;
      }
    }
    &__icon {
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}
@media (max-width: 500px) {
  .card {
    height: 70px;
    &__text {
      &__description {
        font-size: 12px;
      }
      &__title {
        font-size: 18px;
      }
    }
    &__icon {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}
