@media (max-width: 576px) {
  .form__item {
    margin: 0 0 15px 0 !important;
  }

  .line {
    height: 1px;
    background-color: rgb(216, 216, 216);
    width: 100%;
    min-width: 200px;
    border-radius: 30px;
  }
}
