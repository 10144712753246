#contacts {
  height: 100%;
  background: url("../../images/contact_us_image.avif") center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--main-color);
    opacity: 0.7;
    z-index: 1;
  }
  .container {
    padding: 30px 0;
    height: 100%;
    display: flex;
    position: inherit;
    z-index: 10;
    .contact__item {
      width: 50%;
    }
  }
}
#about-us {
  padding: 50px 0;
  height: 100%;
  background: url("../../images/about_us__image_2.png") center center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about-us__content {
    width: 100%;
    height: 80%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: rgba(
      255,
      255,
      255,
      0.5
    ); /* Устанавливаем полупрозрачный белый фон */
    backdrop-filter: blur(10px);
    &__title {
      margin: 20px 0;
      font-size: 22px;
    }
    &__description {
      font-size: 19px;
    }
  }
}

@media (max-width: 768px) {
  #contacts {
    .container {
      flex-direction: column;
      .contact__item {
        width: 100%;
      }
    }
  }
  #about-us {
    .about-us__content {
      &__title {
        font-size: 18px;
      }
      &__description {
        font-size: 15px;
      }
    }
  }
}
@media (max-width: 500px) {
  #contacts {
    .container {
      width: 100%;
      flex-direction: column;
      .contact__item {
        width: 100%;
      }
    }
  }
  #about-us {
    .about-us__content {
      &__title {
        font-size: 16px;
      }
      &__description {
        font-size: 13px;
        margin: 10px 0;
      }
    }
  }
}
@media (max-width: 400px) {
  #about-us {
    .about-us__content {
      &__title {
        margin: 0;
        font-size: 16px;
      }
      &__description {
        font-size: 13px;
      }
    }
  }
}
