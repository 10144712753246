@import "/src/styles/variables.scss";

.international {
  min-height: 500px;

  .step {
    display: flex;
    justify-content: space-between;
    width: fit-content;
    column-gap: 30px;
    min-width: 700px;
    margin: 0px;

    // & > div {
    //   width: 49%;
    // }
  }

  .step__center {
    justify-content: center;
  }

  .step__vehicle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
    & > div {
      min-width: 150px !important;
    }

    .step__vehicle__header {
    }
  }
  .step__vertical {
    display: flex !important;
    flex-direction: column !important;
  }

  .step__1 {
    & > div {
      min-width: 350px;
    }
  }

  .step__4 {
    & > div {
      div {
        min-width: 40%;
      }
    }

    .wrap {
      width: 100%;
      min-width: 250px;
      display: flex;
      justify-content: space-between;
    }
  }

  .wrapper {
    background-color: white;
    padding: 20px;
    border-radius: 1px;

    header {
      margin-bottom: 20px;
    }

    .form {
      & > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }

    .div1 {
      min-width: 48%;
      h1 {
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: $lg) {
  .international {
    min-height: 500px;
    height: fit-content;

    .step {
      flex-direction: column;
      width: fit-content;
      min-width: 150px !important;
      margin: 0px;
    }
    .step__1 {
      & > div {
        min-width: 150px;
      }
    }
  }
}

@media (max-width: $sm) {
  .international {
    h1 {
      font-size: 22px;
    }

    .step__4 {
      & > div {
        div {
          min-width: none;
          width: 100%;
        }
      }
    }
  }
}
