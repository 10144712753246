.form {
  width: 100%;
  height: 100%;
  padding: 50px 25px;
  .inputWrapper {
    margin: 10px 0;
  }
  .recaptchaWrapper {
    width: 100%;
    overflow: hidden;
    margin: 20px 0;
  }
  input::placeholder,
  textarea::placeholder {
    font-size: 18px;
    color: var(--gray) !important;
    text-transform: capitalize;
  }
  label {
    color: white;
  }

  input,
  textarea {
    border: 2px solid silver;
    outline: none;
    margin-top: 5px;
    width: 100%;
    border-radius: 4px;
    font-size: 18px;
    padding: 10px 15px;
    svg {
      fill: none;
      margin: 0 3px;
    }
  }
  input:focus,
  textarea:focus {
    border-color: transparent !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.705) !important;
  }
}
.error {
  color: red;
  font-weight: 700;
}
@media (max-width: 900px) {
  .form {
    input::placeholder,
    textarea::placeholder,
    input,
    textarea {
      font-size: 15px;
    }
  }
}
@media (max-width: 500px) {
  .form {
    input::placeholder,
    textarea::placeholder,
    input,
    textarea {
      font-size: 13px;
    }
    label {
      font-size: 14px;
    }
    svg {
      // width: 17px;
    }
  }
}
