.steppers {
  padding: 20px 0;
  .stepper {
    margin: 24px 0;
    display: flex;
    gap: 15px;
    &__icon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
@media (max-width: 400px) {
  .steppers {
    .stepper {
      align-items: center;
      &__icon{
        width: 35px;
        height: 35px;
      }
      &__text{
        &__title{
          font-size: 15px;
        }
        &__description{
          font-size: 12px;
        }
      }
    }
  }
}
