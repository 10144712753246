.proccess {
  padding: 50px 0;

  &__step {
    &__item {
      min-height: 600px;
      &__stepOrder {
        border-radius: 14px;
        padding: 3px 5px;
        width: 70px;
        text-align: center;
        font-weight: 700;
      }
      &__title {
        font-weight: 600;
        font-size: 29px;
        line-height: 34px;
      }
      &__description {
        font-weight: 700;
        color: var(--dark);
      }
    }
  }
  &__step1 {
    display: flex;
    justify-content: space-between;
    &__item1 {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end;
      padding: 30px 40px;
      &__stepOrder {
        color: #fff;
        background: var(--step-1);
      }
    }
    &__item2 {
      background-color: var(--step-1);
      width: 50%;
      display: flex;
      align-items: center;
    }
  }
  &__step2 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    &__item1 {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      padding: 30px 40px;
      order: 2;
      &__stepOrder {
        color: #5fac9f;

        background-color: #f1fde5;
      }
    }
    &__item2 {
      padding: 50px 0;
      background: #93c264;
      order: 1;
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        padding: 50px 0px;

        max-width: 100%;
      }
    }
  }
  &__step3 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    &__item1 {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end;
      padding: 30px 40px;
      &__stepOrder {
        color: #5fac9f;
        background: #e5fdf9;
      }
      
    }
    &__item2 {
      padding: 50px 0;
      background: #e5fdf9;
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        // padding: 50px 0px;
        max-height: 100%;

        max-width: 100%;
      }
    }
  }
  .container {
    width: 90%;
    float: right;
    max-width: 720px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 800px) {
  .proccess {
    &__step1,
    &__step2,
    &__step3 {
      flex-direction: column;
      &__item1,
      &__item2 {
        width: 100%;
        .container {
          width: 100%;
        }
      }
    }
    &__step2 {
      &__item1 {
        order: 1;
      }
      &__item2 {
        order: 2;
      }
    }
  }
}
@media (max-width: 500px) {
  .proccess {
    &__step1,
    &__step2,
    &__step3 {
      &__item1 {
       
        padding-left: 15px;
      }
    }
    &__step__item__title{
      font-size: 20px;
    }
    &__step__item__description{
      font-size: 13px;
    }
  }
}
@media (max-width: 350px) {
  .proccess {
  
    &__step__item__title{
      font-size: 17px;
      line-height: 25px;
    }
    &__step__item__description{
      font-size: 11px;
    }
  }
}
